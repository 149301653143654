import { authentication, createDirectus, rest } from '@directus/sdk';

// export const Instance = createDirectus(process.env.NEXT_APP_DIRECTUS_URL).with(
//     rest({
//     //   onRequest: (options) => ({ ...options, cache: 'no-store' }),
//     //   onRequest: (options) => ({ ...options, cache: 'default' }),
//       onRequest: (options) => ({ ...options, cache: 'force-cache' }),
//     })
// );

export const Instance = createDirectus(process.env.NEXT_APP_DIRECTUS_URL)
.with(rest({
      onRequest: (options) => ({ ...options, cache: 'no-store' }),
    //   onRequest: (options) => ({ ...options, cache: 'default' }),
    // onRequest: (options) => ({ ...options, cache: 'force-cache' }),
}))

export const AuthenticationInstance = createDirectus(process.env.NEXT_APP_DIRECTUS_URL).with(
    authentication(),
);