import { Instance } from "./instance";
import { login, logout, readMe, withToken, refresh, createUser, createItem, createOne, readItem, readItems, updateItem, updateItems, deleteItem, deleteItems, readUsers, readUser, updateUser, deleteUser, readFlows } from '@directus/sdk';

import { plurification } from '@/lib/helpers';

import { roles } from '@/lib/constants/users';

import Bugsnag from '@bugsnag/js';

export class SDKWrapper {

    constructor(localStorageObject, setLocalStorageObject) {
        this.Instance = Instance
        this.localStorageObject = localStorageObject;
        this.setLocalStorageObject = setLocalStorageObject;
    }

    async refreshTokenIfNeeded(refreshedAuthData) {
        return new Promise((resolve, reject) => {
            const currentTimestamp = new Date().getTime();
            const expiresAt = this.localStorageObject.authData.expires_at;

            const expiresInStr = Math.floor((expiresAt - currentTimestamp) / 60000);
            console.log(`Token will expire in ${expiresInStr} minutes`);
            
            // if (expiresAt - currentTimestamp < 300000) {
                console.log('refreshing token');
                this.refreshToken().then((refreshedAuthData) => {
                    resolve(refreshedAuthData);
                }).catch((error) => {
                    const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                    Bugsnag.notify(HandledError);
                    this.logout();
                });
            // }
        });
    }

    async login(email, password) {
        return new Promise(async (resolve, reject) => {
            if(!email || !password || this.localStorageObject.isLoggedIn) {
                reject('Vul alle velden in.');
            }

            const newObj = {};
            let loginSuccess = false;
            try {
                const authData = await Instance.request(login(email, password, {
                    mode: 'json',
                }));
        
                if (authData) {
                    const expiresInMiliSeconds = authData.expires;
                    const expiresAt = new Date().getTime() + (expiresInMiliSeconds);
                    authData.expires_at = expiresAt;
                    
                    newObj.loading = false;
                    newObj.isLoggedIn = true;
                    newObj.authData = authData;

                    loginSuccess = true;
                } else {
                    newObj.loading = false;
                    newObj.isLoggedIn = false;
                    newObj.authData = null;
                }
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                newObj.loading = false;
                newObj.isLoggedIn = false;
                newObj.authData = null;

                reject('De combinatie van e-mailadres en wachtwoord is onjuist.');
            }

            this.setLocalStorageObject({
                ...this.localStorageObject,
                ...newObj,
            }, 'loginWrapper');

            if (loginSuccess) {
                resolve(true);
            } else {
                reject('Er is iets misgegaan bij het inloggen. Probeer het opnieuw.');
            }
        });
    }

    async logout() {
        await Instance.request(logout(this.localStorageObject.authData.refresh_token, 'json'));
        
        this.setLocalStorageObject({
            ...this.localStorageObject,
            isLoggedIn: false,
            authData: null,
            userData: null,
        }, 'logoutWrapper');

        return window.location.href = '/account/login';
    }

    async refreshToken() {
        return new Promise((resolve, reject) => {
            Instance.request(refresh('json', this.localStorageObject.authData.refresh_token)).then((response) => {

                if (response && response.expires) {
                    const expiresInMiliSeconds = response.expires;
                    const expiresAt = new Date().getTime() + (expiresInMiliSeconds);
                    response.expires_at = expiresAt;
                }

                const newObj = {
                    loading: false,
                    isLoggedIn: true,
                    authData: response,
                };

                this.setLocalStorageObject({
                    ...this.localStorageObject,
                    ...newObj,
                }, 'refreshTokensWrapper');

                setTimeout(() => {
                    resolve(response);
                }, 200);
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.log('refreshTokensWrapper error:', error);
                reject(error);
            });
        });
    }

    async request(request, withAdminToken = false) {
        return new Promise(async (resolve, reject) => {
            // const refreshedAuthData = await this.refreshTokenIfNeeded();
            let tokenToUse = '';
            if (withAdminToken) {
                tokenToUse = process.env.NEXT_APP_DIRECTUS_ADMIN_TOKEN;
            } else {
                tokenToUse = this.localStorageObject.authData.access_token;
            }

            Instance.request(withToken(tokenToUse, request)).then((response) => {
                resolve(response);
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);

                const status = error.response ? error.response.status ?? null : null;

                if(status === 401) {
                    this.logout();
                }

                reject(error);
            });
        });
    }

    async getUsers() {
        const args = {
            fields: [
                'id',
                'email',
                'first_name',
                'last_name',
                'status',
                'role.id',
                'role.name',
                'licenses',
            ],
        };

        return this.request(readUsers(args));
    }

    getRoleName(user) {
        let roleId = '';
        if (typeof user.role === 'string') {
            roleId = user.role;
        } else {
            roleId = user.role.id;
        }

        const userRole = roles[roleId];

        return userRole;
    }

    async getUser(id, withAdminToken = false) {
        const args = {
            fields: [
                'id',
                'email',
                'first_name',
                'last_name',
                'status',
                'role.id',
                'role.name',
                'licenses.id',
                'licenses.levels_id',
            ],
        };

        return this.request(readUser(id, args), withAdminToken);
    }

    async getUserByEmail(email, withAdminToken = false) {
        return new Promise(async (resolve, reject) => {
            const args = {
                fields: [
                    'id',
                    'email',
                    'first_name',
                    'last_name',
                    'status',
                    'role.id',
                    'role.name',
                    'licenses.id',
                    'licenses.levels_id',
                ],
                filter: {
                    email: {
                        _eq: email,
                    },
                },
            };

            const response = await this.request(readUsers(args), withAdminToken);

            if (response.length > 0) {
                resolve(response[0]);
            } else {
                reject('Er bestaat geen gebruiker met dit e-mailadres.');
            }
        });
    }

    async validatePassword(email, password) {
        if(!email || !password) {
            console.error('validatePassword error: email or password is missing');
            
            return false;
        }

        let loginSuccess = false;
        try {
            const loginResponse = await Instance.request(login(email, password, {
                mode: 'json',
            }));
    
            if (loginResponse) {
                loginSuccess = true;
            }

            return loginSuccess;
        } catch (error) {
            const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
            Bugsnag.notify(HandledError);
            console.log('loginWrapper error:', e);
            return false;
        }
    }

    async getUserData() {
        // const args = {
        //     fields: ['*, role.id, licenses.*'],
        // };

        const args = {};
        return this.request(readMe(args));
    }

    async createUser(first_name, last_name, email, licenses, password) {
        return new Promise(async (resolve, reject) => {
            try {
                const data = {
                    first_name,
                    last_name,
                    email,
                    password,
                    licenses: licenses.map((license) => {
                        return {
                            levels_id: license,
                            date_assigned: new Date().toISOString(),
                        }
                    }),
                    role: process.env.NEXT_APP_DIRECTUS_USER_ROLE,
                };

                const response = await this.request(createUser(data));

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('createUser error', error);
    
                if (error.errors[0].extensions.code === 'RECORD_NOT_UNIQUE') {
                    reject('Er bestaat al een gebruiker met dit e-mailadres.');
                } else {
                    reject('Er is iets foutgegaan bij het versturen van het formulier. Probeer het later opnieuw.');
                }
            }
        });
    }

    async updateUser(id, data, withAdminToken = false) {
        return new Promise(async (resolve, reject) => {
            try {
                const updatedData = {
                    ...data,
                    licenses: data.licenses?.map((license) => {
                        return {
                            levels_id: license,
                        }
                    }),
                };

                const response = await this.request(updateUser(id, updatedData), withAdminToken);

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('updateUser error', error);
    
                if (error.errors[0].extensions.code === 'RECORD_NOT_UNIQUE') {
                    reject('Er bestaat al een gebruiker met dit e-mailadres.');
                } else {
                    resolve({
                        id: 'true',
                    });
                }
            }
        });
    }

    async deleteUser(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.request(deleteUser(id));

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('deleteUser error', error);
    
                reject('Er is iets foutgegaan bij het verwijderen van de gebruiker. Probeer het later opnieuw.');
            }
        });
    }

    async createEntry(data) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.request(createItem('forgot_password_entries', data), true);

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('createEntry error', error);
    
                reject('Er is iets foutgegaan bij het toevoegen van het item. Probeer het later opnieuw.');
            }
        });
    }

    async deleteEntry(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.request(deleteItem('forgot_password_entries', id), true);

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('deleteEntry error', error);
    
                reject('Er is iets foutgegaan bij het verwijderen van het item. Probeer het later opnieuw.');
            }
        });
    }

    async getEntryByToken(token) {
        return new Promise(async (resolve, reject) => {
            const args = {
                fields: ['*'],
                filter: {
                    token: {
                        _eq: token,
                    },
                },
            };

            const response = await this.request(readItems('forgot_password_entries', args), true);

            if (response.length > 0) {
                resolve(response[0]);
            } else {
                reject('Er bestaat geen item met deze token.');
            }
        });
    }

    async createItem(collection, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.request(createItem(collection, data));

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('createItem error', error);
    
                reject('Er is iets foutgegaan bij het toevoegen van het item. Probeer het later opnieuw.');
            }
        });
    }

    async updateItem(collection, id, data) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.request(updateItem(collection, id, data));

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('updateItem error', error);
    
                reject('Er is iets foutgegaan bij het bewerken van het item. Probeer het later opnieuw.');
            }
        });
    }

    async deleteItem(collection, id) {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await this.request(deleteItem(collection, id));

                resolve(response);
            } catch (error) {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                console.error('deleteItem error', error);
    
                reject('Er is iets foutgegaan bij het verwijderen van het item. Probeer het later opnieuw.');
            }
        });
    }

    convertGamesToCardsLayout(data, userRole) {
        if (userRole === 'Administrator' || userRole === 'Admin') {
            const levels = data.levels ?? [];
            const levelCategories = levels.reduce((acc, level) => {
                return acc + level.categories.length;
            }, 0);

            const categoryQuestions = levels.reduce((acc, level) => {
                return acc + level.categories.reduce((acc, category) => {
                    return acc + category.questions.length;
                }, 0);
            }, 0);

            const newLevelData = [
                {
                    id: data.id,
                    title: data.name,
                    icon: data.icon,
                    button: {
                        text: 'Bewerken',
                        href: `/admin/games/edit?game=${data.id}`,
                    },
                    subTexts: [
                        `${levels.length} ${plurification(levels.length, 'Level', 'Levels')}`,
                        `${levelCategories} ${plurification(levelCategories, 'Categorie', 'Categorieën')}`,
                        `${categoryQuestions} ${plurification(categoryQuestions, 'Kaart', 'Kaarten')}`,
                    ],
                    subCards: levels.map((level) => {
                        const categoryQuestions = level.categories.reduce((acc, category) => {
                            return acc + category.questions.length;
                        }, 0);
                        const categories = level.categories ?? [];
                        return {
                            id: level.id,
                            title: level.name,
                            icon: level.icon,
                            button: {
                                href: `/admin/games/edit?game=${data.id}&level=${level.id}`,
                                text: 'Bewerken',
                            },
                            subTexts: [
                                `${categories.length} ${plurification(categories.length, 'Categorie', 'Categorieën')}`,
                                `${categoryQuestions} ${plurification(categoryQuestions, 'Kaart', 'Kaarten')}`,
                            ],
                            subCards: categories.map((category) => {
                                const questions = category.questions ?? [];
                                return {
                                    id: category.id,
                                    title: category.name,
                                    button: {
                                        href: `/admin/games/edit?game=${data.id}&level=${level.id}&category=${category.id}`,
                                        text: 'Bewerken',
                                    },
                                    subTexts: [
                                        `${category.questions.length} ${plurification(category.questions.length, 'Kaart', 'Kaarten')}`,
                                    ],
                                    subCards: questions.map((question) => {
                                        return {
                                            id: question.id,
                                            title: question.name,
                                            subTextsAreHTML: true,
                                            subTexts: [
                                                // question.answer ?? ''
                                            ],
                                            button: {
                                                text: 'Bewerken',
                                                href: `/admin/games/edit?game=${data.id}&level=${level.id}&category=${category.id}&question=${question.id}`,
                                            },
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            ]
            return newLevelData;
        } else if (userRole === 'Gebruiker') {
            const levels = data.levels ?? [];

            const levelCategories = levels.reduce((acc, level) => {
                return acc + level.categories.length;
            }, 0);

            const newLevelData = [
                {
                    title: data.name,
                    icon: data.icon,
                    button: {
                        text: 'Go!',
                        href: levels.length > 0 ? `/game?level=${levels[Math.floor(Math.random() * levels.length)].id}` : '/game',
                    },
                    subTexts: [
                        `${levels.length} ${plurification(levels.length, 'Level', 'Levels')}`,
                        `${levelCategories} ${plurification(levelCategories, 'Categorie', 'Categorieën')}`,
                    ],
                    subCards: levels.map((level) => {
                        const categoryQuestions = level.categories.reduce((acc, category) => {
                            return acc + category.questions.length;
                        }, 0);
                        const categories = level.categories ?? [];
                        return {
                            id: level.id,
                            title: level.name,
                            icon: level.icon,
                            href: `/game?level=${data.id}`,
                            button: {
                                text: 'Go!',
                                href: `/game?level=${level.id}`,
                            },
                            subTexts: [
                                `${categories.length} ${plurification(categories.length, 'Categorie', 'Categorieën')}`,
                                `${categoryQuestions} ${plurification(categoryQuestions, 'Kaart', 'Kaarten')}`,
                            ],
                            subCards: categories.map((category) => {
                                const questions = category.questions ?? [];
                                return {
                                    title: category.name,
                                    href: `/game?level=${data.id}&category=${category.id}`,
                                    subTexts: [
                                        `${questions.length} ${plurification(questions.length, 'Kaart', 'Kaarten')}`,
                                    ],
                                    button: {
                                        text: 'Go!',
                                        href: `/game?level=${level.id}&category=${category.id}`,
                                    },
                                }
                            })
                        }
                    })
                }
            ]
            return newLevelData;
        } else {
            console.error('Unknown user role:', userRole);
            return null;
        }
    }

    async getGames(convertToCardsLayout = true, userRole, id = null) {
        return new Promise((resolve, reject) => {
            let deep = {}
            const filter = {
                id: {
                    _eq: 1,
                }
            };

            if (userRole === 'Gebruiker') {
                deep.levels = {
                    _filter: {
                        _and: [
                            {
                                users: {
                                    directus_users_id: {
                                        _eq: id,
                                    }
                                }
                            }
                        ]
                    }
                }
            }

            const fields = [
                'id',
                'name',
                'icon',
                'levels.id',
                'levels.users.*',
                'levels.name',
                'levels.icon',
                'levels.months_valid',
                'levels.categories.id',
                'levels.categories.icon',
                'levels.categories.name',
                'levels.categories.questions.id',
                'levels.categories.questions.name',
                'levels.categories.questions.answer',
            ];

            const args = {
                sort: [
                    'sort',
                    'date_created',
                    'levels.sort',
                    'levels.date_created',
                    'levels.categories.sort',
                    'levels.categories.date_created',
                    'levels.categories.questions.sort',
                    'levels.categories.questions.date_created',
                ],
                filter,
                fields,
                deep,
            };

            this.request(readItems('games', args)).then(async (response) => {
                if (response.length > 0) {
                    const data = response[0];
                    if (convertToCardsLayout) {
                        const cardsData = this.convertGamesToCardsLayout(data, userRole);

                        resolve(cardsData);
                    } else {
                        resolve(data);
                    }
                } else {
                    resolve(null);
                }
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                reject(error);
            });
        });
    }

    async generateQuestionsArray(levels) {
        return new Promise((resolve, reject) => {
            const questions = levels.reduce((acc, level) => {
                return acc.concat(level.categories.reduce((acc, category) => {
                    return acc.concat(category.questions.map((question) => {
                        return {
                            ...question,
                            levelId: level.id,
                            categoryId: category.id,
                        };
                    }));
                }, []));
            }, []);

            resolve(questions);
        });
    }

    async generateAnswererdQuestionsArray(allQuestions, gameEntry) {
        return new Promise((resolve, reject) => {
            const answeredQuestions = gameEntry.question_entries.map((entry) => {
                const question = allQuestions.find((question) => {
                    return question.id === entry.answered_questions_id.question.id;
                });

                return {
                    ...question,
                    ...entry.answered_questions_id,
                };
            });

            resolve(answeredQuestions);
        });
    }

    async getPlayableGame(userRole, userId) {
        return new Promise((resolve, reject) => {
            let deep = {}
            const filter = {
                id: {
                    _eq: 1,
                }
            };

            if (userRole === 'Gebruiker') {
                deep.levels = {
                    _filter: {
                        _and: [
                            {
                                users: {
                                    directus_users_id: {
                                        _eq: userId,
                                    }
                                }
                            }
                        ]
                    }
                }
            }

            const fields = [
                'id',
                'name',
                'icon',
                'levels.id',
                'levels.users.*',
                'levels.sort',
                'levels.name',
                'levels.icon',
                'levels.categories.sort',
                'levels.categories.id',
                'levels.categories.icon',
                'levels.categories.name',
                'levels.categories.questions.sort',
                'levels.categories.questions.id',
                'levels.categories.questions.name',
                'levels.categories.questions.answer',
            ];

            const args = {
                sort: [
                    'sort',
                    'date_created',
                    'levels.sort',
                    'levels.date_created',
                    'levels.categories.sort',
                    'levels.categories.date_created',
                    'levels.categories.questions.sort',
                    'levels.categories.questions.date_created',
                ],
                filter,
                fields,
                deep,
            };

            this.request(readItems('games', args)).then(async (response) => {
                if (response.length > 0) {
                    const data = response[0];

                    data.levels = data.levels.sort((a, b) => {
                        return a.sort - b.sort;
                    });

                    data.levels.forEach((level) => {
                        level.categories = level.categories.sort((a, b) => {
                            return a.sort - b.sort;
                        });

                        level.categories.forEach((category) => {
                            category.questions = category.questions.sort((a, b) => {
                                return a.sort - b.sort;
                            });
                        });
                    });

                    this.getGameEntryByUserAndGameId(userId, data.id).then(async (gameEntry) => {
                        // We use this to calculate the game progress
                        const allQuestions = await this.generateQuestionsArray(data.levels);
                        const allAnsweredQuestions = await this.generateAnswererdQuestionsArray(allQuestions, gameEntry);

                        gameEntry.question_entries = allAnsweredQuestions;

                        const questionsPerLevel = [];

                        // We use this to calculate the progress per level
                        const percentageDonePerLevel = data.levels.map((level) => {
                            const levelQuestions = allQuestions.filter((question) => {
                                return question.levelId === level.id;
                            });

                            questionsPerLevel.push({
                                level: level,
                                questions: levelQuestions,
                            });

                            questionsPerLevel.forEach((level) => {
                                level.questions.forEach((question) => {
                                    const answeredQuestion = gameEntry.question_entries.find((entry) => {
                                        return entry.id === question.id;
                                    });
                                    
                                    if (answeredQuestion) {
                                        question.user_was_right = answeredQuestion.user_was_right;
                                    } else {
                                        question.user_was_right = null;
                                    }
                                });
                            });

                            const answeredLevelQuestions = allAnsweredQuestions.filter((question) => {
                                return question.levelId === level.id;
                            });

                            const levelQuestionCount = levelQuestions.length;
                            const answeredLevelQuestionsCount = answeredLevelQuestions.length;

                            const percentage = Math.round((answeredLevelQuestionsCount / levelQuestionCount) * 10000) / 100;

                            return {
                                levelId: level.id,
                                sort: level.sort,
                                percentage,
                            };
                        });

                        resolve({
                            ...gameEntry,
                            allQuestions,
                            questionsPerLevel,
                            percentageDonePerLevel: percentageDonePerLevel,
                            percentageDone: Math.round((allAnsweredQuestions.length / allQuestions.length) * 10000) / 100,
                        });
                    }).catch((error) => {
                        const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                        Bugsnag.notify(HandledError);
                        reject(error);
                    });
                } else {
                    resolve(null);
                }
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                reject(error);
            });
        });
    }

    async getGameEntryByUserAndGameId(userId, gameId, isFirstTime = true) {
        return new Promise(async (resolve, reject) => {
            if (!userId || !gameId) {
                reject('userId or gameId is missing');
                return;
            }

            this.request(readItems('user_game_entries', {
                filter: {
                    user: {
                        _eq: userId,
                    },
                    game: {
                        _eq: gameId,
                    },
                },
                sort: ['-id'],
                fields: [
                    'id',
                    'game.id',
                    'game.name',
                    'game.levels.id',
                    'game.levels.categories.id',
                    'game.levels.categories.questions.id',
                    'user.id',
                    'user.email',
                    'question_entries.*',
                    'question_entries.answered_questions_id.question.sort',
                    'question_entries.answered_questions_id.question.id',
                    'question_entries.answered_questions_id.question.name',
                    'question_entries.answered_questions_id.question.answer',
                    'question_entries.answered_questions_id.user_was_right',
                ],
            })).then((response) => {
                if (response.length > 0) {
                    resolve(response[0]);
                } else {
                    if (!isFirstTime) {
                        reject('Er is geen game entry gevonden voor deze gebruiker en dit spel.');
                        return;
                    }

                    this.request(createItem('user_game_entries', {
                        user: userId,
                        game: gameId,
                    })).then(() => {
                        this.getGameEntryByUserAndGameId(userId, gameId, false).then((refetchResponse) => {
                            resolve(refetchResponse);
                        }).catch((error) => {
                            const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                            Bugsnag.notify(HandledError);
                            reject(error);
                        });
                    }).catch((error) => {
                        const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                        Bugsnag.notify(HandledError);
                        reject(error);
                    });
                }
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                reject(error);
            });
        });
    }

    async createGameEntry(userId, gameId) {
        return new Promise(async (resolve, reject) => {
            this.request(createItem('user_game_entries', {
                user: userId,
                game: gameId,
            })).then((response) => {
                resolve(response);
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                reject(error);
            });
        });
    }

    async createQuestionEntry(gameId, userId, questionId, userWasRight) {
        return new Promise(async (resolve, reject) => {
            if (!userId) {
                reject('userId is missing');
                return;
            }

            // Get users game entry
            const entry = await this.getGameEntryByUserAndGameId(userId, gameId);
            
            // Check if question entry already exists
            const questionEntryExists = entry.question_entries.find((entry) => {
                return entry.answered_questions_id.question.id === questionId;
            });

            const existingQuestionEntries = entry.question_entries.map((entry) => {
                return entry.id;
            });

            let data = null;
            if (questionEntryExists) {
                data = {
                    question_entries: [
                        ...entry.question_entries.map((entry) => {
                            if (entry.answered_questions_id.question.id === questionId) {
                                return {
                                    ...entry,
                                    answered_questions_id: {
                                        ...entry.answered_questions_id,
                                        user_was_right: userWasRight,
                                    },
                                };
                            }

                            return entry;
                        }),
                    ],
                };
            } else {
                data = {
                    question_entries: [
                        ...existingQuestionEntries,
                        {
                            user_game_entries_id: entry.id,
                            answered_questions_id: {
                                question: questionId,
                                user_was_right: userWasRight,
                            },
                        }
                    ],
                };
            }

            // Update user entry with new question entry
            this.request(updateItem('user_game_entries', entry.id, data)).then((response) => {
                resolve(response);
            }).catch((error) => {
                const HandledError = new Error(`Catched: ${(typeof error === 'string') ? error : error.message}`);
                Bugsnag.notify(HandledError);
                reject(error);
            });
        });
    }
}