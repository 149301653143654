export const statusses = {
    draft: "Concept",
    invited: "Uitgenodigd",
    unverified: "Niet geverifieerd",
    active: "Actief",
    suspended: "Geblokkeerd",
    archived: "Gearchiveerd",
};

export const roles = {};
roles[process.env.NEXT_APP_DIRECTUS_ADMIN_ROLE] = "Administrator";
roles[process.env.NEXT_APP_DIRECTUS_MANAGER_ROLE] = "Admin";
roles[process.env.NEXT_APP_DIRECTUS_USER_ROLE] = "Gebruiker";

export default {
    statusses,
    roles,
}